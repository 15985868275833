export const tableFields = {
  // data Trigger
  TRIGGER: [
    {
      key: "id",
      label: "No",
      _style: "width:1%",
      sorter: false,
      filter: true,
    },
    {
      key: "trigger_name",
      label: "トリガー名",
      _style: "width:1%;white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    {
      key: "created_at",
      label: "トリガー作成日時",
      _style: "width:1%;white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    {
      key: "trigger_setting",
      label: "条件設定      ",
      _style: "width:1%;white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ],
  // data Slag
  SLAG: [
    // {
    //   key: "id",
    //   label: "No",
    //   _style: "width:0%",
    //   sorter: false,
    //   filter: true,
    // },
    {
      key: "slag_name",
      label: "スラグ名",
      _style: "width:10%;white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    {
      key: "slag_name_en",
      label: "スラグ名（英字）",
      _style: "width:10%;white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    {
      key: "created_at",
      label: "スラグ 作成日時",
      _style: "width:10%;white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    // {
    //   key: "updated_at",
    //   label: "スラグ 最終更新日",
    //   _style: "width:1%;white-space: nowrap !important;",
    //   sorter: false,
    //   filter: true,
    // },
    {
      key: "action",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ],
  CONTENT4: [
    // {
    //   key: "ID",
    //   label: "NO",
    //   _style: "min-width:100px",
    //   sorter: false,
    //   filter: true,
    // },
    {
      key: "title",
      label: "タイトル",
      _style: "width:35%;white-space: nowrap !important;",
      sorter: true,
      filter: true,
    },
    // {
    //   key: "category_name",
    //   label: "カテゴリ名 ",
    //   _style: "min-width:150px;white-space: nowrap !important;",
    //   sorter: true,
    //   filter: true,
    // },
    {
      key: "updated_at",
      label: "最終更新日時",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  CONTENT1: [
    {
      key: "title",
      label: "タイトル",
      _style: "width:35%;white-space: nowrap !important;",
      sorter: true,
      filter: true,
    },
    {
      key: "product_price",
      label: "価格",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  CONTENT2: [
    {
      key: "title",
      label: "タイトル",
      _style: "width:35%;white-space: nowrap !important;",
      sorter: true,
      filter: true,
    },
    {
      key: "author",
      label: "著者",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  CONTENT3: [
    {
      key: "title",
      label: "タイトル",
      _style: "width:35%;white-space: nowrap !important;",
      sorter: true,
      filter: true,
    },
    {
      key: "product_type",
      label: "提案",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  PAGE: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "min-width:100px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "page_title",
      label: "ページタイトル",
      _style: "width:350px",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "email",
    //   label: "作成者",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: false,
    // },
    {
      key: "updated_at",
      label: "最終更新日時",
      _style: "min-width:200px",
      sorter: true,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: true,
      filter: false,
    },
  ],
  LIST_OWNER: [
    {
      key: "no",
      label: "NO",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "first_name",
      label: "オーナー名",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "id",
      label: "オーナーID",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "email",
      label: "メールアドレス",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "width: 10%",
      sorter: false,
      filter: false,
    },
  ],
  USER: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "min-width:100px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "name",
      label: "ユーザー名",
      _style: "min-width:120px",
      sorter: false,
      filter: false,
    },
    {
      key: "email",
      label: "メールアドレス",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "width: 10%",
      sorter: false,
      filter: false,
    },
  ],
  CATEGORY_NEW: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "min-width:100px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "title",
      label: "カテゴリ名", //son
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  PURCHASE_USER_SUBSCRIPTION: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "content_title",
      label: "タイトル",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "category_name",
    //   label: "カテゴリ",
    //   _style: "min-width:200px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "created_at",
      label: "取得日時",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  PURCHASE_USER_SUBSCRIPTION_SP: [
    {
      key: "content_title",
      label: "商品名",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "created_at",
      label: "購入時期",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  PURCHASE_SUBSCRIPTION: [
    {
      key: "content_title",
      label: "商品名",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "created_at",
      label: "購入時期",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:140px",
      sorter: false,
      filter: false,
    },
  ],
  PURCHASE_USER_ONE_TIME: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "content_title",
      label: "タイトル",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "category_name",
    //   label: "カテゴリ",
    //   _style: "min-width:200px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "created_at",
      label: "取得日時",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  PURCHASE_USER_ONE_TIME_SP: [
    {
      key: "content_title",
      label: "タイトル",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "created_at",
      label: "取得日時",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  NEWS: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "min-width:100px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "news_title",
      label: "ニュースタイトル",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "title",
      label: "カテゴリ",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "post_date",
      label: "投稿日",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "updated_at",
      label: "最終更新日時",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    // {
    //   key: "is_public",
    //   label: "ステータス",
    //   _style: "min-width:200px",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "action",
      label: "",
      _style: "width: 10%",
      sorter: false,
      filter: false,
    },
  ],
  NEW_ADMIN: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "news_title",
      label: "ニュースタイトル",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "post_date",
      label: "投稿日",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "is_public",
      label: "ステータス",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "width: 10%",
      sorter: false,
      filter: false,
    },
  ],
  USER_ADMIN: [
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: true,
      filter: false,
    },
    {
      key: "user_id",
      label: "ID",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "first_name",
      label: "FIRST NAME",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "last_name",
      label: "LAST NAME",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "email",
      label: "EMAIL",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "shop_id",
      label: "SHOP ID",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "shop_name",
      label: "SHOP NAME",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "address",
      label: "ADDRESS",
      _style: "min-width:250px",
      sorter: true,
      filter: true,
    },
    {
      key: "birthday",
      label: "BIRTHDAY",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "sex",
      label: "SEX",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "tel",
      label: "TEL",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "user_type",
      label: "USER TYPE",
      _style: "min-width:120px",
      sorter: true,
      filter: true,
    },
    {
      key: "is_active",
      label: "STATUS",
      _style: "min-width:100px",
      sorter: false,
      filter: true,
    },
    {
      key: "admin_rank",
      label: "ADMIN RANK",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "point",
      label: "POINT",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "created_by",
      label: "CREATE BY",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "stripe_id",
      label: "STRIPE ID",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "stripe_key",
      label: "STRIPE KEY",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "paypal_id",
      label: "PAYPAL ID",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "paypal_key",
      label: "PAYPAL KEY",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
  ],
  CHECKOUT: [
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "id",
      label: "ID",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "payment_id",
      label: "payment_id",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "status_order",
      label: "status_order",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "total_amount",
      label: "total_amount",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "total_payment_amount",
      label: "total_payment_amount",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "total_point_received",
      label: "total_point_received",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "total_point_use",
      label: "total_point_use",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "payment_type",
      label: "payment_type",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "message_error",
      label: "message_error",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "buyer",
      label: "Buyer",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "created_at",
      label: "created_at",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
  ],
  SETTINGPOINT: [
    {
      key: "point_title",
      label: "タイトル",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "point_amount",
      label: "付与ポイント",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    // {
    //   key: "point_expiration_type",
    //   label: "ポイント有効ゆうこう期限きげんタイプ",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "point_expiration_value",
    //   label: "Point Expiration Value",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "action_type",
    //   label: "アクションタイプ",
    //   _style: "min-width:150px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "created_at",
    //   label: "で作成",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    {
      key: "action",
      label: "",
      _style: "min-width:150px",
      sorter: false,
      filter: false,
    },
  ],
  HISTORYPOINT: [
    // {
    //   key: "points",
    //   label: "Point Amount",
    //   _style: "min-width:150px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "day_expiration",
    //   label: "Day Expiration",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "action_type",
    //   label: "Action Type",
    //   _style: "min-width:150px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "first_day_receive_point",
    //   label: "First Day Receive Point",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "last_day_receive_point",
    //   label: "Last Day Receive Point",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    // {
    //   key: "created_at",
    //   label: "Created At",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    {
      key: "id",
      label: "No",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "point",
      label: "Point Amount",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "price",
      label: "Price",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "created_at",
      label: "Created At",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
  ],
  SURVEY: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "survey_title",
      label: "タイトル",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:150px",
      sorter: false,
      filter: false,
    },
  ],

  QA: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "email",
      label: "メールアドレス",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "name",
      label: "氏名",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "created_at",
      label: "お問い合わせ日時",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    // {
    //   key: "status",
    //   label: "ステータス",
    //   _style: "min-width:200px",
    //   sorter: true,
    //   filter: true,
    // },
    {
      key: "action",
      label: "",
      _style: "min-width:150px",
      sorter: false,
      filter: false,
    },
  ],
  CATEGORY: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "width:5%",
    //   sorter: false,
    //   filter: false,
    // },
    // {
    //   key: "category_name",
    //   label: "カテゴリ名",
    //   _style: "width:50%",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "category_name",
      label: "カテゴリ",
      _style: "width:20%",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  ORDER: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:40px",
      sorter: false,
      filter: false,
    },
    {
      key: "title",
      label: "コンテンツ名",
      _style: "min-width:200px;white-space: nowrap !important;",
      sorter: true,
      filter: false,
    },
    {
      key: "name",
      label: "ユーザー名",
      _style: "min-width:120px;white-space: nowrap !important;",
      sorter: true,
      filter: false,
    },
    {
      key: "total_amount",
      label: "金額(円)",
      _style: "width:100px;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "status_order",
      label: "ステータス",
      _style: "min-width:100px;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "created_at",
      label: "注文日時",
      _style: "min-width:90px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  AUTHOR: [
    // {
    //   key: "id",
    //   // label: "管理者ID/著者ID",
    //   label: "ID",
    //   _style: "min-width:100px;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "name",
      label: "管理者名/著者名",
      _style: "min-width:150px; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "email",
      label: "メールアドレス",
      _style: "min-width:200px;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px;",
      sorter: false,
      filter: false,
    },
  ],
  USERNOTE: [
    {
      key: "ID",
      label: "NO",
      _style: "min-width:5%",
      sorter: false,
      filter: true,
    },
    {
      key: "title",
      label: "コンテンツタイトル",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "create_start",
      label: "ノート作成日時",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "update_end",
      label: "ノート最終更新日時",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width: 100px",
      sorter: false,
      filter: true,
    },
  ],
  LIST_NOTE_USER: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: true,
      filter: true,
    },
    {
      key: "title",
      label: "コンテンツタイトル",
      _style: "min-width:200px",
      sorter: true,
      filter: true,
    },
    {
      key: "created_at",
      label: "ノート作成日時",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "updated_at",
      label: "ノート最終更新日時",
      _style: "min-width:150px",
      sorter: true,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  SALE_MANAGEMENT_MONTHLY: [
    {
      key: "month",
      label: "対象月",
      _style: "min-width:100px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_quantity",
      label: "販売件数",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_revenue",
      label: "売上総計（円）",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  SALE_MANAGEMENT_DAILY: [
    {
      key: "date",
      label: "対象日",
      _style: "min-width:100px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_quantity",
      label: "販売件数",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_revenue",
      label: "売上総計（円）",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  DETAIL_SALE_MANAGEMENT_DAILY: [
    {
      key: "date",
      label: "対象日",
      _style: "min-width:100px",
      sorter: false,
      filter: true,
    },
    {
      key: "title",
      label: "購入コンテンツ",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_amount",
      label: "売上（円）",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "name",
      label: "購入ユーザー名",
      _style: "min-width:100px",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  SALE_MANAGEMENT_RANK: [
    {
      key: "id",
      label: "順位",
      _style: "min-width:100px",
      sorter: false,
      filter: true,
    },
    {
      key: "name",
      label: "ユーザー名",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_revenue",
      label: "売上（円）",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "total_quantity",
      label: "購入件数",
      _style: "min-width:150px",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  FIXED_PAGE: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "width: 1%; white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "title",
      label: "タイトル",
      _style: "width: 1%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "is_public",
    //   label: "ステータス",
    //   _style: "width: 1%; white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    // {
    //   key: "type",
    //   label: "カテゴリ",
    //   _style: "width: 1%; white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "updated_at",
      label: "最終更新日時",
      _style: "width: 1%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "width: 10%",
      sorter: false,
      filter: false,
    },
  ],
  TIMING_POPUP: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "popup_title_admin",
      label: "ポップアップ名（管理用）",
      _style: "min-width:200px;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "created_at",
      label: "作成日時",
      _style: "min-width:200px;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      sorter: false,
      filter: false,
    },
  ],
  TIMING_MAIL: [
    {
      key: "id",
      label: "NO",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
    {
      key: "management_name",
      label: "管理名",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "title",
      label: "メールタイトル",
      _style: "min-width:200px",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      sorter: false,
      filter: false,
    },
  ],
  URL: [
    // {
    //   key: "ID",
    //   label: "NO",
    //   sorter: false,
    //   filter: true,
    // },
    // {
    //   key: "short_url",
    //   label: "短縮URL",
    //   _style: "width: 1%; white-space: nowrap !important;",
    //   sorter: false,
    //   filter: true,
    // },
    {
      key: "original_url",
      label: "元URL",
      _style: "width: 1%; white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "width: 1%; white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
  ],
  ALL_NOTE: [
    // {
    //   key: "id",
    //   label: "NO",
    //   _style: "width: 1%",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "name",
      label: "ユーザ名",
      _style: "width: 15%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "email",
      label: "メールアドレス",
      _style: "width: 15%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "title",
    //   label: "コンテンツ名",
    //   _style: "width: 15%;white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "page_title",
      label: "ページ名",
      _style: "width: 15%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "title_note",
      label: "タイトル",
      _style: "width: 15%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "content_note",
    //   label: "メモ",
    //   _style: "width: 15%; white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "tags",
      label: "タグ",
      _style: "width: 15%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "width: 10%; white-space: nowrap !important;",
      sorter: false,
      filter: true,
    },
  ],
  ALL_CART: [
    {
      key: "name",
      label: "ユーザ名",
      _style: "width: 25%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "email",
      label: "メールアドレス",
      _style: "width: 25%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "content_name",
      label: "カート内",
      _style: "width: 50%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
  ],
  MAIL_MEGA: [
    {
      key: "title",
      label: "タイトル",
      _style: "min-width:200px",
      sorter: false,
      filter: true,
    },
    {
      key: "action",
      label: "",
      _style: "width: 10%",
      sorter: false,
      filter: true,
    },
  ],
  CHAPTER: [
    {
      key: "id",
      label: "NO",
      _style: "width:5%",
      sorter: false,
      filter: false,
    },
    {
      key: "title",
      label: "章タイトル",
      _style: "width:50%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "id_chapter",
      label: "章ID",
      _style: "width:20%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  PURCHASE_POINT: [
    {
      key: "id",
      label: "NO",
      _style: "width:5%",
      sorter: false,
      filter: false,
    },
    {
      key: "point",
      label: "点",
      _style: "width:50%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "price",
      label: "価格",
      _style: "width:20%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "min-width:100px",
      sorter: false,
      filter: false,
    },
  ],
  CHAT_BY_ADMIN: [
    {
      key: "name",
      label: "お名前",
      _style: "width:30%%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "email",
    //   label: "メールアドレス",
    //   _style: "width:20%;white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "created_at",
      label: "作成時間",
      _style: "width:15%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "updated_at",
      label: "更新日時",
      _style: "width:15%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "title",
      label: "コンテンツ名",
      _style: "width:15%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "chapter",
      label: "目次",
      _style: "width:15%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    // {
    //   key: "message",
    //   label: "質問",
    //   _style: "width:20%;white-space: nowrap !important;",
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: "action",
      label: "",
      _style: "width:15%;white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
  ],
  MODAL_PAGE: [
    {
      key: "page_title",
      label: "タイトル",
      _style: "width: 50%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
    {
      key: "updated_at",
      label: "更新日時",
      _style: "width: 25%; white-space: nowrap !important",
      sorter: true,
      filter: false,
    },
    {
      key: "action",
      label: "",
      _style: "width: 25%; white-space: nowrap !important;",
      sorter: false,
      filter: false,
    },
  ],
};
